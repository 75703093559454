import { useStaticQuery, graphql } from "gatsby"
import React from "react"

import Gallery from '@browniebroke/gatsby-image-gallery'


export default function GalleryNew() {
  const data = useStaticQuery(graphql`
    query ImagesForGalleryEdition1 {
    allFile(
    sort: {order: ASC, fields: name}
    filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "first-edition"}}) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 375
              height: 320
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`)
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <div className=" bg-green-default">
    <div className="section-container">
    <Gallery 
    images={images} 
    colWidth="1/1"
    mdColWidth="3/3"
    gutter="0.7rem"
    />
    </div>
    </div>
  )
}
