import React from "react";

function Videos() {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl  mx-auto pt-16 md:pb-20 pb-10">
        <div className="flex flex-col items-center justify-center text-center ">
          <div className="vimeoframe">
            <iframe src="https://player.vimeo.com/video/411446741" allow="autoplay; fullscreen" allowfullscreen="" width="640" height="360" frameborder="0"></iframe>
          </div>
          <div className="vimeoframe">
<iframe src="https://player.vimeo.com/video/411448322" allow="autoplay; fullscreen" allowfullscreen="" width="640" height="1138" frameborder="0"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Videos;
