import React from "react";

function Bioblinds() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full justify-center">
          <h1 className="heading">Bioblinds 1st Edition IVY</h1>
        </div>
        <div className="bg-white px-6 py-5 md:mt-20 mt-10">
          <p className="para-graph ">
            Our ‚Bioblinds 1st Edition IVY‘ marks the start of the iterative
            research for ‚Bioblinds – The Green Skyline Initiative‘. You can
            download the impulse instruction manual and adapt the mechanism to
            your needs when constructing your own Bioblinds. Please share your
            findings, feedback and also critic after you registered to the
            Bioblinds community. Scroll down to see some impressions of the
            prototype IV.
          </p>

          <p className=" text-lg text-black-default underline hover:no-underline duration-150 ease-linear transition-all cursor-pointer">
            Register to download and collaborate on our first impulse
            instruction manual for plant curtains with ivy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Bioblinds;
